@import "~antd/dist/antd.css";

html {background: #f8f8f8;}

body {margin: 0;padding: 0;font-family: sans-serif;background: #f8f8f8;}

.ant-menu,.ant-menu-submenu > .ant-menu {background: transparent;}

#purchase_confirm_upload{display:none;}

#purchase_error_status{display:none;}

#sales_error_status{display: none;}

#sales_confirm_upload{display:none;}
#pull_button_click{display:none;}
#pull_external_click{display:none;}
/* .ant-modal{width: 920px !important;} */

#root.loading {overflow: hidden;}

#root.loading .modal {display: block;}

.modal {display: none;position: fixed;z-index: 1000;top: 0;left: 0;height: 100%;width: 100%;background: rgba(255, 255, 255, .8)url('http://sampsonresume.com/labs/pIkfp.gif') 50% 50% no-repeat;}

.blur {overflow: hidden;filter: blur(3px);}

.custom_loading {position: absolute;margin-left: 48%;margin-top: 20%;z-index: 1;width: 5%;}

#purchase_download{display:none;}

#sales_download{display:none;}

#year, #financialMonth { height: 100%;display: flex;flex-direction: column;justify-content: center; }

#financialMonth { }

#yearDiv {margin-left: 3%; margin-top: 1%}

#monthDiv {margin-left: 7%; margin-top: 1%}

.ant-btn-primary{color: #fff; background-color: #108ee9 !important;border-color: #108ee9;}

.ant-btn-danger{color: #fff; background-color: #f04134 !important;border-color: #f04134;}

.ant-btn-warning{color: #fff; background-color: #ffee00 !important;border-color: #ffee00;}

.ant-btn-success{color: #fff; background-color: #10c51f !important;border-color: #10c51f;}

.ant-btn.active, .ant-btn:active {
    color: #ffffff;
    border-color: #ffffff;
}

.ant-btn:focus, .ant-btn:hover {
  color: #ffffff;
  border-color: #ffffff;
}

.summary_table{width:100%;border: solid 1px #eae5e5;}

.summary_table_head{background: #f1f1f1;}

.text-center{text-align: center;padding: 10px;}

.ant-btn:hover { color: #000000 !important;}

.ant-table-title { padding: 8px 0; position: relative; top: 1px; border-radius: 4px 4px 0 0; font-size: 15px; text-align: center; color: #ffffff;background:#375f8cf7}

/* .ant-confirm-confirm{width: 416px !important;} */

.ant-confirm-btns > .ant-btn-lg{display:none;}

.ant-confirm-btns > .ant-btn-primary{display: initial; }

#reconcile_summary_table{display: none;}

.ant-confirm-body .ant-confirm-title { color: rgb(93, 85, 64); font-weight: 700; font-size: 14px;}

a:hover{color:rgb(12, 12, 12);}

a{color:white;}

p { margin-bottom: 0em; }

.ant-table-thead > tr > th {text-align: center;}

.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
    padding: 10px;
    word-break: unset;
}

.ant-popover-title{background: #02ccc2;border-top-left-radius: 5px;border-top-right-radius: 5px;color: white;font-weight: bold;}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow, .ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow, .ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    border-top-width: 0;
    border-bottom-color: hsl(177, 98%, 40%);
    top: 3px;
}

.custom-filter-dropdown {
  padding: 8px !important;
  border-radius: 6px !important;
  background: #fff !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2) !important;
}

.custom-filter-dropdown input {
  width: 180px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.custom-filter-dropdown button {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.highlight {
  color: #f50 !important;
}

/* .ant-table-scroll > .ant-table-body {overflow-x: scroll !important; max-width: 1080px !important;} */

/* .ant-table-fixed > .ant-table-tbody > tr:nth-child(even){background-color: #7ee69e;} */
/* .ant-table-fixed > .ant-table-tbody > tr:nth-child(4n), div:nth-child(4n-1) {
    background: #37c763;
}
.ant-table-fixed > .ant-table-tbody > tr:nth-child(4n-2), div:nth-child(4n-3) {
    background: white;
} */
/* .ant-table-tbody>tr.ant-table-row-hover>td, .ant-table-tbody>tr:hover>td, .ant-table-thead>tr.ant-table-row-hover>td, .ant-table-thead>tr:hover>td {
    background: #f2ff25;
} */
/*
.ant-table-fixed > .ant-table-tbody > tr > td:nth-last-child(1){background-color: white;} */

.scroll {width: 200px;height: 400px;background: blue;overflow: scroll;}
.scroll::-webkit-scrollbar {width: 10px;height: 10px;}
.scroll::-webkit-scrollbar-track {-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); border-radius: 10px;}
.scroll::-webkit-scrollbar-thumb {border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);background-color: #525050;}

/* .ant-table-thead>tr>th {
    background: #f39202;
    font-weight: 500;
    font-weight: bold;
    -webkit-transition: background .3s ease;
    -o-transition: background .3s ease;
    transition: background .3s ease;
    color: white;
} */

/* .ant-table-fixed > .ant-table-tbody {background: #37c763;} */

.alignCenter{text-align: center !important;}
/* .ant-pagination.ant-table-pagination{ margin-right: 520px;} */
/* .my-pagination{margin-right: 465px !important;}
.items-pagination{margin-right: 285px !important;}
.itc-items-pagination {margin-right: 395px!important;}
.list-pagination{margin-right: 275px !important;}
.recon-item-my-pagination{margin-right: 405px!important;} */

.ant-card-head-title {
    white-space: unset;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  background: #466f9a; color: white;  
}

.pr-register-reconcile-mismatch .ant-table-selection-column span {
  display: none;
}

.ant-layout-sider-children {
  overflow-y: auto;
}

.gstr9Pt2 .ant-input-number {
  width: 100%;
}

.gstr9pt3sec7otherreversals .ant-table-thead {
  display:none;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-bottom: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}